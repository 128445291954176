@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

.quiz-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quiz-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -25px;
}
.logo-head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ques-count {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 400 !important;
}

.quiz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  width: 500px;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 22px !important;

  color: #333;
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  padding: 20px;
}

.question-text {
  font-size: 22px !important;
  margin-bottom: 20px;
  color: #333;
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;

  text-align: center;
}

.answer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ans-btn {
  width: 350px;
  margin-bottom: 12px;
  border: none;
  outline: none;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
}
/* .btnClicked {
  background: #eeeeee !important;
} */
button {
  width: 350px;
  margin-bottom: 12px;
  border: none;
  outline: none;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
}
/* button:active {
  background-color: #ee4e14;
  color: #fff;
  transition: 0.3s all ease-in;
} */

button:hover {
  background-color: #ee4e14;
  color: #fff;
  transition: 0.3s all ease-in;
}

@media only screen and (max-width: 620px) {
  .quiz {
    box-shadow: none;
  }
}
@media only screen and (max-width: 500px) {
  .quiz {
    width: 300px;
    padding-top: 15px;
  }

  button {
    width: 250px;
    background-color: #eeeeee !important;
    color: #333 !important;
  }

  .ques-count {
    font-size: 14px !important;
  }
  .question-text {
    font-size: 18px !important;
  }
}
.logo {
  width: 300px;
  height: 100px;
}
.logo-btn {
  border: none;
  background-color: #fff !important;
  color: black;
  margin-right: 15px;
  cursor: pointer;
}
.logo-btn-soccer {
  width: 60px;
  border: none;
  background-color: #fff !important;
  margin-right: -10px;
  cursor: pointer;
  margin-top: 10px;
}
.logo-btn-soccer:hover {
  background-color: #fff !important;
  color: #ee4e14 !important;
}
.logo-btn:hover {
  background-color: #fff !important;
  color: #ee4e14 !important;
}

.icon:hover {
  color: #ee4e14 !important;
}
.icon-2 {
  color: #333;
  font-weight: bolder;
}

.logo-header {
  margin-bottom: -20px;
}
.ques-count {
  margin-bottom: 25px !important;
}

.sign-up {
  width: 150px;
  color: #fff !important;
  background-color: #ee4e14 !important;
}
.sign-up:hover {
  opacity: 0.8;
  transition: 0.2s all ease-in !important;
}

.result-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submit {
  background: #ee4e14 !important;
  width: 130px;
  color: #fff;
  margin-top: 25px;
}

.submit:hover {
  opacity: 0.8;
  transition: 0.2s all ease-in-out;
}
.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;

  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;

  border-radius: 15px;
  width: 400px;
  font-family: "Poppins", sans-serif;
}
.result h4 {
  margin-top: 40px !important;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.result h1 {
  font-size: 150px;
  color: #ee4e14;
  margin: 0;
  margin-top: -25px;
  margin-bottom: -25px;
}
.result p {
  font-weight: bold;
  margin-bottom: 45px;
  font-family: "Poppins", sans-serif;
}
.small {
  font-size: 12px;
  font-weight: lighter;
  color: gray;
  margin-top: 0px;
}
.sign-up {
  width: 150px;
  background-color: #ee4e14;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .result {
    box-shadow: none;
    width: 350px !important;
  }
  .form {
    box-shadow: none;
  }
}
@media screen and (max-width: 520px) {
  .result {
    width: 300px;
    box-shadow: none;
    margin-top: -35px !important;
  }
  .result h4,
  .result p {
    text-align: center !important;

    font-size: 14px;
  }
  .result p {
    margin-bottom: 20px;
  }
  .sign-up {
    margin-top: -20px !important;
  }
}

.touch {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

input {
  width: 250px;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #eeeeee !important;
  color: #333;
}

.strong {
  font-weight: bold;
  color: #333;
  margin-top: 15px;
  text-align: center !important;
}
p {
  color: #333;
  margin-top: -10px;
}
.form-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
}
.form {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  width: 500px;
}
.link {
  text-decoration: none;
  color: #fff;
}
.form p {
  font-weight: lighter;
  text-align: center !important;
}
@media screen and (max-width: 640px) {
  .form {
    box-shadow: none;
    width: 350px;
  }
}
@media screen and (max-width: 520px) {
  .form {
    width: 300px;
    box-shadow: none !important;
  }
  .strong {
    font-size: 15px;
    text-align: center !important;
  }
  .form p {
    font-size: 14px;
    text-align: center !important;
  }

  input {
    width: 200px;
  }
}
