@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

.navbar{
font-family: 'Poppins', sans-serif;
height: 80px;
display:flex;
justify-content:center;
align-items:center;
font-size:1rem;
top:0;
z-index:10;
font-weight:300;
}
.navbar-container{
display:flex;
justify-content:space-between;
height:80px;
z-index:1;
width:100%;
padding:24px;
 max-width:1200px;
}

.nav-logo{
 justify-self:flex-start;
cursor:pointer;
display:flex;
align-items:center;
margin-left:0px;
}
 .nav-menu{
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin-right:-22px;
    
 }
.nav-item{
    height:80px;
    text-decoration: none;

}
.nav-link{
    color:#333;
    display: flex;
    justify-content: center;
    align-items:center;
    padding:0 1rem;
    height:100%;
    cursor:pointer;
    text-decoration:none;
     
}

 
.nav-link:hover {
    color:  #ee4e14;
}


 
.img{
    width: 200px;
    margin: auto;
    height: 100px;
  }
     
    
 
 @media only screen and (max-width:520px) {

   
    .nav-link {
        font-size: 16px;
        width:200px;
        margin-left: -50px;
    }
    .navbar-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nav-logo{
        
        height:40px;
        margin-top: 50px;
      
    }
    .img{
        width:250px !important;
        height: 80px !important;
    }
    .nav-item{
        height:15px;
    }
 }
 @media only screen and (max-width:330px) {
     .img{
         width:120px;
         height:80px;
     }
     .nav-link {
         margin-left: -60px;
     }
 }