@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding:0;
  margin:0;
}

 
.navbar{
font-family: 'Poppins', sans-serif;
height: 80px;
display:flex;
justify-content:center;
align-items:center;
font-size:1rem;
top:0;
z-index:10;
font-weight:300;
}
.navbar-container{
display:flex;
justify-content:space-between;
height:80px;
z-index:1;
width:100%;
padding:24px;
 max-width:1200px;
}

.nav-logo{
 justify-self:flex-start;
cursor:pointer;
display:flex;
align-items:center;
margin-left:0px;
}
 .nav-menu{
display:flex;
align-items:center;
list-style:none;
text-align:center;
margin-right:-22px;
    
 }
.nav-item{
    height:80px;
    text-decoration: none;

}
.nav-link{
    color:#333;
    display: flex;
    justify-content: center;
    align-items:center;
    padding:0 1rem;
    height:100%;
    cursor:pointer;
    text-decoration:none;
     
}

 
.nav-link:hover {
    color:  #ee4e14;
}


 
.img{
    width: 200px;
    margin: auto;
    height: 100px;
  }
     
    
 
 @media only screen and (max-width:520px) {

   
    .nav-link {
        font-size: 16px;
        width:200px;
        margin-left: -50px;
    }
    .navbar-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nav-logo{
        
        height:40px;
        margin-top: 50px;
      
    }
    .img{
        width:250px !important;
        height: 80px !important;
    }
    .nav-item{
        height:15px;
    }
 }
 @media only screen and (max-width:330px) {
     .img{
         width:120px;
         height:80px;
     }
     .nav-link {
         margin-left: -60px;
     }
 }
.hero-head{
  background: url(/static/media/landing.854be4d3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:100vh;
  object-fit: cover;
}
.hero {
  
  display: flex;
  flex-direction: column;
 
}
 
.hero-content {
  margin: 0 auto ;
  margin-top: 50px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   
}
.first {
  color: #ee4e14;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.second {
  color: #333;
  margin-top: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  text-align: center;
  max-width: 600px;
  font-weight: lighter;
  line-height: 2rem;
}
.pick {
  color: #ee4e14;
  font-family: 'Poppins', sans-serif;
  margin-top: -5px;
  font-size: 14px;
  text-align: center;
  max-width: 600px;
  margin-bottom: -20px;
}
.HeroBtnWrapper {
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  align-items: center;
}
.btn {
  border-radius: 5px;
  background: #ee4e14;
   
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
   padding:12px  !important;
   font-weight: bold;
}
.btn:hover {
  transition: all o.2s ease-in-out;
  opacity: 0.8;
}
.grade{
  margin-top: -50px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 520px) {
  .HeroBtnWrapper {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    gap: 0px;
    margin-bottom: 20px;
  }
   .btn {
    font-size: 16px !important;
    padding: 8px 10px !important;
  } 
  .first{
    margin-bottom: 20px;
  }
  .first,.second {
    font-size: 20px;
    line-height: 1.5rem;
  }
}
.created {
  text-align: center;
  color:gray
}
@media screen and (max-width: 400px) {
    .first{
        font-size: 15px;

    }
    .second{
        font-size: 15px;
    }
    .created {
      font-size:15px;
    }
}
.utn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 30px;
  font-family: "Poppins", sans-serif;
}
.content {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-weight: 350;
}
@media only screen and (max-width: 520px) {
  .content {
    width: 100%;
  }
}
.logo {
  width: 200px !important;
  margin: auto;
  height: 150px;
}
.btn {
  margin-top: 40px;
  width: 150px;
  text-decoration: none;
  padding: 15px;
  color: #fff;
  background-color: #ee4e14 !important;
}
p {
  line-height: 1.6rem;
  /* margin-top: 15px !important; */
}
.margin{
  margin-bottom: 25px;
}
.note {
  color: #ee4e14;
}
 

/* Move to quiz.css */
* {
  box-sizing: border-box;
}
.progress {
  background-color: #333;
  position: relative;
  margin: 15px 0;
  height: 10px;
  width: 300px;
}

.progress-done {
  background: #ee4e14;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  transition: 0.1s ease 0.3s;
}
.progressBar {
  background-color: #333;
  position: relative;
  margin: 15px 0;
  height: 10px;
  width: 300px;
}
.progressBar-done {
  background: #ee4e14;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 1;
}
@media only screen and (max-width: 520px) {
  .progressBar, .progress {
    width: 220px;
  }
}

.quiz-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quiz-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -25px;
}
.logo-head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ques-count {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 400 !important;
}

.quiz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  width: 500px;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 22px !important;

  color: #333;
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  padding: 20px;
}

.question-text {
  font-size: 22px !important;
  margin-bottom: 20px;
  color: #333;
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;

  text-align: center;
}

.answer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ans-btn {
  width: 350px;
  margin-bottom: 12px;
  border: none;
  outline: none;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
}
/* .btnClicked {
  background: #eeeeee !important;
} */
button {
  width: 350px;
  margin-bottom: 12px;
  border: none;
  outline: none;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
}
/* button:active {
  background-color: #ee4e14;
  color: #fff;
  transition: 0.3s all ease-in;
} */

button:hover {
  background-color: #ee4e14;
  color: #fff;
  transition: 0.3s all ease-in;
}

@media only screen and (max-width: 620px) {
  .quiz {
    box-shadow: none;
  }
}
@media only screen and (max-width: 500px) {
  .quiz {
    width: 300px;
    padding-top: 15px;
  }

  button {
    width: 250px;
    background-color: #eeeeee !important;
    color: #333 !important;
  }

  .ques-count {
    font-size: 14px !important;
  }
  .question-text {
    font-size: 18px !important;
  }
}
.logo {
  width: 300px;
  height: 100px;
}
.logo-btn {
  border: none;
  background-color: #fff !important;
  color: black;
  margin-right: 15px;
  cursor: pointer;
}
.logo-btn-soccer {
  width: 60px;
  border: none;
  background-color: #fff !important;
  margin-right: -10px;
  cursor: pointer;
  margin-top: 10px;
}
.logo-btn-soccer:hover {
  background-color: #fff !important;
  color: #ee4e14 !important;
}
.logo-btn:hover {
  background-color: #fff !important;
  color: #ee4e14 !important;
}

.icon:hover {
  color: #ee4e14 !important;
}
.icon-2 {
  color: #333;
  font-weight: bolder;
}

.logo-header {
  margin-bottom: -20px;
}
.ques-count {
  margin-bottom: 25px !important;
}

.sign-up {
  width: 150px;
  color: #fff !important;
  background-color: #ee4e14 !important;
}
.sign-up:hover {
  opacity: 0.8;
  transition: 0.2s all ease-in !important;
}

.result-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submit {
  background: #ee4e14 !important;
  width: 130px;
  color: #fff;
  margin-top: 25px;
}

.submit:hover {
  opacity: 0.8;
  transition: 0.2s all ease-in-out;
}
.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;

  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;

  border-radius: 15px;
  width: 400px;
  font-family: "Poppins", sans-serif;
}
.result h4 {
  margin-top: 40px !important;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.result h1 {
  font-size: 150px;
  color: #ee4e14;
  margin: 0;
  margin-top: -25px;
  margin-bottom: -25px;
}
.result p {
  font-weight: bold;
  margin-bottom: 45px;
  font-family: "Poppins", sans-serif;
}
.small {
  font-size: 12px;
  font-weight: lighter;
  color: gray;
  margin-top: 0px;
}
.sign-up {
  width: 150px;
  background-color: #ee4e14;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .result {
    box-shadow: none;
    width: 350px !important;
  }
  .form {
    box-shadow: none;
  }
}
@media screen and (max-width: 520px) {
  .result {
    width: 300px;
    box-shadow: none;
    margin-top: -35px !important;
  }
  .result h4,
  .result p {
    text-align: center !important;

    font-size: 14px;
  }
  .result p {
    margin-bottom: 20px;
  }
  .sign-up {
    margin-top: -20px !important;
  }
}

.touch {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

input {
  width: 250px;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #eeeeee !important;
  color: #333;
}

.strong {
  font-weight: bold;
  color: #333;
  margin-top: 15px;
  text-align: center !important;
}
p {
  color: #333;
  margin-top: -10px;
}
.form-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
}
.form {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  width: 500px;
}
.link {
  text-decoration: none;
  color: #fff;
}
.form p {
  font-weight: lighter;
  text-align: center !important;
}
@media screen and (max-width: 640px) {
  .form {
    box-shadow: none;
    width: 350px;
  }
}
@media screen and (max-width: 520px) {
  .form {
    width: 300px;
    box-shadow: none !important;
  }
  .strong {
    font-size: 15px;
    text-align: center !important;
  }
  .form p {
    font-size: 14px;
    text-align: center !important;
  }

  input {
    width: 200px;
  }
}

body {
  font-family: "Poppins", sans-serif;
}

