@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
.hero-head{
  background: url("../Images/landing.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:100vh;
  object-fit: cover;
}
.hero {
  
  display: flex;
  flex-direction: column;
 
}
 
.hero-content {
  margin: 0 auto ;
  margin-top: 50px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   
}
.first {
  color: #ee4e14;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.second {
  color: #333;
  margin-top: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  text-align: center;
  max-width: 600px;
  font-weight: lighter;
  line-height: 2rem;
}
.pick {
  color: #ee4e14;
  font-family: 'Poppins', sans-serif;
  margin-top: -5px;
  font-size: 14px;
  text-align: center;
  max-width: 600px;
  margin-bottom: -20px;
}
.HeroBtnWrapper {
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  align-items: center;
}
.btn {
  border-radius: 5px;
  background: #ee4e14;
   
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
   padding:12px  !important;
   font-weight: bold;
}
.btn:hover {
  transition: all o.2s ease-in-out;
  opacity: 0.8;
}
.grade{
  margin-top: -50px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 520px) {
  .HeroBtnWrapper {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-bottom: 20px;
  }
   .btn {
    font-size: 16px !important;
    padding: 8px 10px !important;
  } 
  .first{
    margin-bottom: 20px;
  }
  .first,.second {
    font-size: 20px;
    line-height: 1.5rem;
  }
}
.created {
  text-align: center;
  color:gray
}
@media screen and (max-width: 400px) {
    .first{
        font-size: 15px;

    }
    .second{
        font-size: 15px;
    }
    .created {
      font-size:15px;
    }
}