@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

.utn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 30px;
  font-family: "Poppins", sans-serif;
}
.content {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  font-weight: 350;
}
@media only screen and (max-width: 520px) {
  .content {
    width: 100%;
  }
}
.logo {
  width: 200px !important;
  margin: auto;
  height: 150px;
}
.btn {
  margin-top: 40px;
  width: 150px;
  text-decoration: none;
  padding: 15px;
  color: #fff;
  background-color: #ee4e14 !important;
}
p {
  line-height: 1.6rem;
  /* margin-top: 15px !important; */
}
.margin{
  margin-bottom: 25px;
}
.note {
  color: #ee4e14;
}
 
